.llp-container{
    width: 92%;
  }
.llp-create-form-width {
    width: 37%;
    min-width: 300px;
  }
.memberuser-table-container{position: relative;
overflow-x: auto;
min-width: 300px;
width: 100%;
min-height: 300px;
scrollbar-width: thin;}
.our-partners-container{
    width: 92%;
}
.our-partners-form-width{
    width: 80%;
}
@media only screen and (max-width: 1450px){
    .our-partners-form-width{
        width: 100%;
    }
}
.opportunities_create_table_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  opacity: 1;
  height: 15rem;
  overflow-x: auto;

}
.FAQ_opportunities_create_table_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  opacity: 1;
  height: 33rem;
}

.inputField_error__border {
  padding: 0.35rem 0.8rem;
  border: 1.5px solid #cccccc;
}
.inputField__border {
  padding: 0.35rem 0.8rem;
  border: 1.5px solid #cccccc;
}
.inputField::placeholder {
  color: #464646;
  font-family: "Nunito", sans-serif;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.9;
  position: absolute;
  top: 24%;
}
.inputField {
  color: #464646;
  font-family: "Nunito", sans-serif;
  font-size: 15px;
  font-weight: 500;
  opacity: 0.9;
}
.admin-create-password [type="password"] {
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: -webkit-font-smoothing;
  letter-spacing: 2px;
}

::-webkit-file-upload-button {
  display: none;
}
.menu_option_contianer {
  position: absolute;
  box-shadow: 0px 0px 25px #68728433;
  border-radius: 8px;
  background-color: #ffff;
  z-index: 999;
  top: 1.5rem;
  right: 0.5rem;
}

.mainlayout_upcide__logo {
  width: 150px;
  height: 80px;
}
.mainlayout_logo_container {
  width: fit-content;
}
.mainlayout_main_children {
  margin-top: 4.5rem;
}
.mainlayout_sidebar_container {
  border: 1px solid hsl(0, 0%, 85%);
  min-height: 100vh;
  width: 250px;
  position: fixed;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 7;
}
.mainlayout_sidebar_container::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.mainlayout_sidebar_container::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.mainlayout_sidebar_container::-webkit-scrollbar-thumb {
  background-color: #0000002E;
}
.mainlayout_topbar_container {
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0%;
}
.mainlayout_topbar_inner__container {
  padding: 1.4rem 1rem;
}
.mainlayout_container {
  background-color: #fafafa;
  width: 100%;
  max-width: 2500px;
}
.mainlayout_maincontent_container {
  width: calc(100% - 250px);
}
.mainlayout_sidebar_inner__container {
  top: 0.2rem;
}

.mainlayout_sidebar_display {
  display: block;
}

@media only screen and (max-width: 992px) {
  .mainlayout_maincontent_container {
    width: 100%;
  }
  .mainlayout_sidebar_container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .mainlayout_logo_container {
    width: auto;
  }
  .mainlayout_sidebar_display {
    display: none;
  }
}

@media only screen and (max-width: 992px) and (max-height: 650px) {
  .mainlayout_sidebar_container {
    position: absolute;
    width: 100%;
    top: 0;
    min-height: fit-content;
    padding-bottom: 14px;
  }
  .mainlayout_sidebar_Active {
    position: fixed;
    background-color: #fafafa;
    width: 100vw;
    height: 100vh;
    display: block;
    opacity: 0.8;
    z-index: 6;
    overflow: hidden;
  }
}

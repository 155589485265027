.oppor_edit__container {
  padding: 1rem 2.4rem 3rem 2.4rem;
}

.oppor_edit_inner__container {
  width: 92%;
}
.opportunities-form-width {
  width: 80%;
}
.opportunities_create_summary_text_area {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  opacity: 1;
  color: #464646;
  font-family: "Nunito", sans-serif;
  font-size: 15px;
  font-weight: 500;
  opacity: 0.9;
  height: 8.5rem;
  resize: none;
}

.opportunities_create_summary_text_area::placeholder {
  color: #464646;
  font-family: "Nunito", sans-serif;
  font-size: 13px;
  font-weight: 500;
  opacity: 0.9;
}
.opportunities_create_summary_text_area:focus {
  outline: none !important;
}

.opportunities_analytics_table__container {
  overflow-x: auto;
  min-width: 300px;
  width: 100%;
  min-height: 250px;
  scrollbar-width: thin;
}
.opportunities_analytics_table__container::-webkit-scrollbar {
  background-color: #fbfbfb;
  width: 8px;
  height: 10px;
}
.opportunities_analytics_table__container::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #00000029;
}
.opportunities_over_flow {
  overflow-y: auto;
  overflow-x: hidden;
  height: 62vh;
}

.opportunities_edit_content_table_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  opacity: 1;
  height: fit-content;
}
@media only screen and (max-width: 576px) {
  .oppor_edit_inner__container {
    width: 100%;
  }
}
@media only screen and (max-width: 1450px) {
  .opportunities-form-width {
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .oppor_edit__container_table {
    padding: 1rem 1rem 3rem 1rem;
  }
}
@media only screen and (max-width: 576px) {
  .oppor_edit__container_table {
    padding: 1rem 0.5rem 3rem 0.5rem;
  }
}

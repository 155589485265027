.oppor_read_headtag__container {
  box-shadow: 0px 0px 6px #00000029;
  z-index: 999;
}
.oppor_read_headtag_color__mainbg {
  background-color: #a5d5d5;
}
.oppor_read_headtag_color__bg {
  background-color: #d6eff6;
}

.oppor_read_progress__bar {
  background: #112f6a;
  border-radius: 20px;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}

.oppor_read_progress__track {
  background-color: #ccdfdd;
  border-radius: 20px;
  position: relative;
  height: 18px;
  width: 95%;
}
.oppor_read_featurecard__imgContianer {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 2px solid #112f6a;
  box-shadow: 0px 0px 6px #00000029;
}
.oppor_read_featurecard__img {
  width: 35px;
  height: 35px;
}
.oppor_read_uitlities__container {
  border-bottom: 1px solid #dee2e6;
}
.oppor_read_uitlities_img_speedo__container {
  /* transform: rotate(-125deg); */
}
.overview_title {
  border-bottom: 3px solid #a5a5a5;
}
.input_file_field {
  border: 2px solid #fad100;
  border-radius: 5px;
  width: fit-content;
}
.fileopen_text {
  margin-top: -9%;
}

.navbar_tags_invoice_discount {
  z-index: 9;
  top: 100%;
  left: 15%;
}
.oppor_nav_list_tagdropdown {
  background: #d6eff6 0% 0% no-repeat padding-box;
  border-radius: 25px;
  top: 50%;
}
.oppor_nav_list_tagdropdown::after {
  content: "";
  width: 25px;
  height: 25px;
  transform: rotate(-45deg);
  background: #d6eff6;
  position: absolute;
  z-index: -5;
  bottom: 65px;
  left: calc(60% - 10px);
}
.oppor_nav_list_tagdropdown__item {
  border-radius: 17px;
}
.accordion_list {
  border: 2px solid #f2f2f2;
  white-space: unset;
  border-radius: 5px;
}
.drop_down {
  background: #ffffff;
  color: #464646;
  flex-direction: column;
  padding-left: 2rem;
}
.wrap {
  background: #ffffff;
  color: #fff;
  display: flex;
  cursor: pointer;
}
.something_container {
  background: transparent
    linear-gradient(97deg, #273978 0%, #324999 37%, #273978 100%) 0% 0%
    no-repeat padding-box;
}
.upcide_image_container {
  opacity: 27%;
}

/********* Carousel ********/

.previous_icon_container {
  position: absolute;
  top: 38%;
  /* left: 5rem; */
  z-index: 5;
  display: inline-block;
  background-color: #ffff !important;
  border-radius: 0 150px 150px 0;
}
.previous_icon {
  position: absolute;
  top: 40% !important;
  left: 0%;
}
.previous {
  width: 50px;
  height: 85px;
  display: block;
  top: 1rem;
  left: -10px;
}

.next_icon_container {
  position: absolute;
  top: 38%;
  z-index: 5;
  display: inline-block;
  background-color: #ffff !important;
  border-radius: 150px 0 0 150px;
  right: 0rem;
}

.next {
  width: 50px;
  height: 85px;
  display: block;
  right: -24px;
  top: 12px;
}

.next_icon {
  position: absolute;
  top: 20% !important;
  right: 0%;
}
.carousel_container {
  display: flex;
  justify-content: center;
  position: relative;
}
.closed_text {
  background: #ffff;
  z-index: 5;
  right: 0rem;
  border-radius: 0px 12px 0px 12px;
  box-shadow: 0px 0px 6px #00000029;
}
.carousel_image {
  border-radius: 13px;
  height: 25rem !important;
}


/********* Insights Card *******/


.insights_card_container{
  width: 24rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 25px #00000012;
  border-radius: 15px !important;
}
.insights_card_img {
  border-radius: 10px 10px 0px 0px;
  width: 24rem;
}
.insights_card_img_container {
  height: 220px;
}
.insights_card_right_arrow {
  position: absolute;

  bottom: -6%;
  left: -0.6rem;
  max-width: 28%;
}
.insights_header_title_container {
  top: 15%;
  left: 0;
  width: 100%;
}
.insights_header_whole_container{
  box-shadow: 0px 0px 16px #00000029;
}


/******** opportunitiesCard ********/
.myhold_card_top_flipsection_container {
  height: 21.5rem;
  box-shadow: 0px 15px 10px -8px #00000029;
  border-radius: 15px;
  overflow-y: auto;
  /* width: 17.85rem; */
  /* animation-duration: 0.5s;
  animation-name: animate-fade_back_side;
  animation-delay: 0.2s;
  animation-fill-mode: backwards; */
}
.myhold_card_container {
  box-shadow: 0px 0px 25px #00000029;
  background-color: #ffff;
  border-radius: 15px;
  width: 21rem;
  height: fit-content;
}
.myhold_card_top_sec_container {
  height: 21.5rem;
  /* animation-duration: 0.5s;
  animation-name: animate-fade_front_side;
  animation-delay: 0.3s;
  animation-fill-mode: backwards; */
  overflow: hidden;
  padding-top: 0.9rem;
}
.myhold_card_item_container {
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
}
.myhold_card_icon_close_container {
  /* width: 7px;
  height: 7px; */
  position: relative;
}
.myhold_card_icon_bell_container {
  width: 22px;
  height: 22px;
  position: relative;
}
.myhold_card_icon_bellwait_container {
  width: 16px;
  height: 16px;
  position: relative;
}
.myhold_card_icon_bell_closed_main_container {
  padding: 0.7rem 0.7rem 0.7rem 0.7rem;
}
.myhold_card_icon_leftarrow_container {
  width: 24px;
  height: 24px;
  position: relative;
}
.myhold_card_icon_rightarrow_container {
  /* width: 5.873px;
  height: 9.511px; */
  position: relative;
}
.myhold_card_icon_speedo_container {
  /* width: 65px;
  height: 65px; */
  position: relative;
  /* transform: rotate(-125deg); */
}
.opportunitycard_icon_arrow_container {
  width: 5.873px;
  height: 9.511px;
  position: relative;
  display: block;
}
.myhold_card_image {
  border-radius: 10px;
}
.myhold_card_image_container {
  width: 100%;
  height: 12rem;
  position: relative;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #00000029;
}
.myhold_card_icon_heart_container {
  width: 24px;
  height: 24px;
  position: relative;
}
.myhold_card_icon_main_container {
  padding: 0.35rem;
}
.myhold_card_icon_monthly_tenture_container {
  width: 22px;
  height: 22px;
  position: relative;
}

.myhold_card_icon_monthly_container {
  width: 20px;
  height: 20px;
  position: relative;
}

.myhold_card_btn {
  background-color: #ffffff;
  border: 2px solid #112f6a;
  border-radius: 5px;
  padding: 0.48rem 0.3rem 0.48rem 0.1rem;
}
.myhold_card_btn:hover {
  background-color: #112f6a;
  border: 2px solid #112f6a;
  border-radius: 5px;
  padding: 0.48rem 0.3rem 0.48rem 0.1rem;
  color: #ffff !important;
}
.myhold_card_icon_arrow_container {
  width: 15px;
  height: 15px;
  position: relative;
}
/* .myhold_card_opacity {
  opacity: 0.4;
} */
.myhold_card_color_gray {
  color: #666666;
}
.myhold_card_color_green {
  color: #669e98;
}
.triangle {
  clip-path: polygon(0 0, 100% 100%, 0 100%);
  transform: rotate(270deg);
  width: 124px;
  height: 124px;
  border-radius: 5px;
  position: absolute;
  right: -0.4%;
  bottom: -0.5%;
  box-shadow: 0px 0px 6px #00000029;
}
.card_logo_container {
  position: relative;
  left: 25%;
  top: 60%;
  z-index: 2;
}

/**********TAGS (TEXT)*************/
.myhold_tag_bottom {
  top: 0.7rem;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.card_text_buyBack {
  transform: rotate(90deg);
  position: relative;
  right: 31%;
  top: 67%;
}
.myhold_card_text_membertrade {
  letter-spacing: 1.8px;
  font-size: 9px;
}
.myhold_card_text_completed {
  z-index: 5;
  top: 45%;
  left: 0%;
  padding: 0.35rem 0rem 0.35rem 0rem;
}
.myhold_card_text_closedwait {
  z-index: 10;
  top: 30%;
  left: 0%;
  padding: 0.8rem 0rem 0.8rem 0rem;
  box-shadow: 0px 0px 6px #00000029;
}
.myhold_card_text_main_heading {
  letter-spacing: 2px;
}
.myhold_card_fs_9 {
  font-size: 9px;
}
.myhold_card_fs_11 {
  font-size: 11px;
}
.myhold_card_text_closed {
  height: 8rem;
  overflow: auto;
}
.myhold_card_top_left_tag {
  z-index: 15;
  font-size: 0.7rem;
  top: 19%;
  left: -40%;
  padding: 0.5rem 8rem 0.5rem 8rem;
  transform: rotate(-45deg);
  box-shadow: 0px 0px 25px #00000029;
}
.myhold_card_bottom_left_tag {
  z-index: 15;
  box-shadow: 0px 0px 25px #00000029;
}

.my_card_title_wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@media only screen and (max-width: 576px) {
  .myhold_card_container {
    width: 20rem;
  }
}

/****Animation****/
@keyframes animate-fade_front_side {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
@keyframes animate-fade_back_side {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 576px) {
  .oppor_read_featurecard__imgContianer {
    width: 45px;
    height: 45px;
  }
  .oppor_read_featurecard__img {
    width: 18px;
    height: 18px;
  }
  .navbar_tags_invoice_discount {
    z-index: 9;
    top: 100%;
    left: 5%;
  }
}
@media only screen and (max-width: 992px) {
  .overflow_navbar {
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    width: 100%;
    white-space: nowrap;
  }
  .overview_section_title {
    display: none;
  }

  .overflow_navbar::-webkit-scrollbar {
    display: none;
  }
}
.accordian_container {
  width: 664px;
}

@media only screen and (max-width: 767px) {
  .accordion_list {
    max-width: 413px !important;
  }

  .accordian_container {
    max-width: 500px !important;
  }
}

@media (min-width: 650px) and (max-width: 1200px) {
  .accordion_list {
    max-width: 520px !important;
  }

  .accordian_container {
    max-width: 520px !important;
  }
}

@media only screen and (max-width: 452px) {
  .accordion_list {
    max-width: 321px !important;
  }

  .accordian_container {
    max-width: 321px !important;
  }
}
@media only screen and (max-width: 350px) {
  .nav_scroll {
    overflow-x: scroll;
  }
}
@media only screen and (max-width: 430px){
  .insights_card_img {
    width: 19.5rem;
  }
  .insights_card_container{
    width: 19.5rem;
    box-shadow: 0px 0px 25px #00000012;
  }
  .insights_card_img {
    width: 19.5rem;
    height: 100%;
  }
}
@media only screen and (min-width: 1200px){
  .InsightsCardMultiple{ 
    display: flex;
  } 
}


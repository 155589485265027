.admin_user_container {
  width: 92%;
}
.admin-user-form-width {
  width: 35%;
  min-width: 300px;
}

.admin_aprvls_vrsn_hstry_data_container {
  border: 1px solid var(---d9d9d9);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  opacity: 1;
  width: 70%;
  overflow-y: auto;
  min-height: 30rem;
  height: fit-content;
}
.our-team-form-width {
  width: 80%;
}
@media only screen and (max-width: 1450px) {
  .our-team-form-width {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .admin_aprvls_vrsn_hstry_container {
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .admin_aprvls_vrsn_hstry_data_container {
    width: 80%;
    height: 23rem;
    margin-left: 2rem;
  }
}

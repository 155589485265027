.wishlist-container {
    width: 92%;
  }
  .wishlist-form-width {
    width: 37%;
    min-width: 300px;
  }
  
  
  .wishlist_aprvls_vrsn_hstry_data_container {
    border: 1px solid var(---d9d9d9);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d9d9d9;
    border-radius: 11px;
    opacity: 1;
    width: 70%;
    overflow-y: auto;
    height: 30rem;
  }
  
  @media only screen and (max-width: 576px) {
    .wishlist-container {
      width: 100%;
    }
  }
  
  
  @media only screen and (max-width: 992px) {
    .wishlist_aprvls_vrsn_hstry_data_container {
      width: 80%;
      height: 23rem;
      margin-left: 2rem;
    }
  }
  
  
  
.opportunities_bottom_navbar_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 0px 0px 10px 10px;
  opacity: 1;
}

.opportunities_bottom_navbar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 0px 0px 16px 16px;
  opacity: 1;
}

.user_view_container {
  width: 92%;
}
.user_view_version_btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 20px;
  opacity: 1;
  border: none;
}
.user_view_overFlow_container {
  overflow-y: scroll;
}
.personal_information_details_width {
  width: 19%;
}

.personal_information_width {
  width: 25%;
}
.personal_information_image_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 9rem;
  width: 9rem;
  border-radius: 1rem;
}
.personal_information_img_size {
  width: 100%;
  height: 100%;
}
.kyc_information_details_width {
  width: 30%;
}
.kyc-status-form-width {
  min-width: 200px;
}
.kyc_selectField__border {
  border-bottom: 2px solid #a5a5a5;
}
.kyc_select-input-field ::placeholder {
  color: #000000de;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: 600;
}
.user_view_kyc_apply_button {
  background: #ffffff 0% 0% no-repeat;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 50px;
  opacity: 1;
}
.address_pan_bank_data_width {
  width: 20%;
}
.user_edit_profile_picture_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 9rem;
  width: 38%;
  border-radius: 0.5rem;
}
.blog_create_image_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 9rem;
  width: 10rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
}
.user_edit_front_back_image_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 9rem;
  width: 10rem;
  border-radius: 0.5rem;
}

.user_edit_ProofType_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  opacity: 1;
  height: 9.3rem;
  width: 170px;
  border-radius: 1rem;
}
.user-form-width {
  width: 36%;
  min-width: 300px;
}
.user_edit_profile_picture_remove_container {
  right: 0%;
  top: -5%;
}

.user_aprvls_vrsn_hstry_data_container {
  border: 1px solid var(---d9d9d9);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  opacity: 1;
  width: 70%;
  height: 23rem;
}
.upload_document_input {
  visibility: hidden;
  width: 0%;
}

.user-edit-profile-picture {
  width: 100%;
  height: 9rem;
  border-radius: 0.5rem;
  border: none;
}

@media only screen and (max-width: 1450px) {
  .personal_information_width {
    width: 33.2%;
  }
  .personal_information_details_width {
    width: 25%;
  }
  .kyc_information_details_width {
    width: 38%;
  }
  .address_pan_bank_data_width {
    width: 25%;
  }
  .address_pan_bank_data_width {
    width: 25%;
  }
  .user_edit_profile_picture_container {
    height: 10rem;
    width: 60%;
  }
  .user_edit_front_back_image_container {
    height: 10rem;
  }
  .user-edit-profile-picture {
    width: 100%;
    height: 10rem;
    border-radius: 0.5rem;
    border: none;
  }
}

@media only screen and (max-width: 576px) {
  .user_view_container {
    width: 100%;
  }
  .personal_information_width {
    width: 50%;
  }
  .personal_information_details_width {
    width: 50% !important;
  }
  .address_pan_bank_data_width {
    width: 50% !important;
  }
}
@media only screen and (max-width: 768px) {
  .personal_information_details_width {
    width: 33.2%;
  }
  .kyc_information_details_width {
    width: 50% !important;
  }
  .address_pan_bank_data_width {
    width: 33.2%;
  }
}
@media only screen and (max-width: 1200px) {
  .kyc_information_details_width {
    width: 25%;
  }
  .user-form-width {
    width: 50%;
    min-width: 300px;
  }
}
@media only screen and (max-width: 992px) {
  .user_edit_profile_picture_container {
    height: 10rem;
    width: 60%;
  }
  .user_edit_front_back_image_container {
    height: 10rem;
    width: 60%;
  }
  .user_aprvls_vrsn_hstry_data_container {
    width: 80%;
    height: 23rem;
    margin-left: 2rem;
  }
}

.llp_message_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 8px;
  opacity: 1;
  outline: none;
  resize: none;
  font-size: 15px;
  padding: 0.35rem 0.8rem;
}
.llp_message_container::placeholder {
  font-size: 14px;
  margin: 0.1rem 0.3rem;
}

.llp_document_radio:checked {
  background-color: #112f6a;
  box-shadow: 0 0 0 1px #112f6a;
}
.llp_document_radio {
  border: 2px solid #fff;
  box-shadow: 0 0 0 1px #a5a5a5;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all 0.2s ease-in;
  margin: 1px;
}

.llp_document_upload{
  border: 1px solid #D9D9D9;
border-radius: 11px;
}

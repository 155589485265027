.admin_role_container {
    width: 92%;
  }
  .admin-role-form-width {
    width: 35%;
    min-width: 300px;
  }
  .admin_role_view_permissions_width {
    width: 50%;
  }
  .admin_role_aprvls_vrsn_hstry_data_container {
    border: 1px solid var(---d9d9d9);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d9d9d9;
    border-radius: 11px;
    opacity: 1;
    width: 70%;
    min-height: 23rem;
  }
  @media only screen and (max-width: 992px) {
    .admin_role_aprvls_vrsn_hstry_data_container {
      width: 80%;
      min-height: 23rem;
      margin-left: 2rem;
    }
  }
  
  
  @media only screen and (max-width: 1200px) {
    .admin_role_view_permissions_width {
      width: 70%;
    }
  }
  @media only screen and (max-width: 768px) {
    .admin_role_view_permissions_width {
      width: 80%;
    }
  }
  @media only screen and (max-width: 576px) {
    .admin_role_container {
      width: 100%;
    }
    .admin_role_view_permissions_width {
      width: 100%;
    }
  }
  
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Mulish:wght@300;400;500;600;700;800;900&family=Nunito:wght@300;400;500;600;700;800;900&family=Raleway:wght@300;400;500;600;700;800;900&family=Roboto:wght@300;400;500;700;900&display=swap");
html {
  padding: 0;
  margin: 0;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa !important;
}

/******COLORS*****/
.primary_color {
  color: #c8a700;
}
.popup_title_color {
  color: #000000de;
}
.full_black_color {
  color: #000000;
}
.secondary_color {
  color: #112f6a;
}
.tertiary_color {
  color: #fad100;
}
.white_shade_color {
  color: #fbfbfb;
}
.light_grey_color {
  color: #a5a5a5;
}
.dark_grey_color {
  color: #666666;
}
.darker_grey_color {
  color: #707070;
}
.dark_title_color {
  color: #464646;
}
.light_green_color {
  color: #669e98;
}
.green_color {
  color: #005e54;
}
.light_red_color {
  color: #d20000;
}
.dark_red_color {
  color: #e61212;
}
.black_color {
  color: #333333;
}
.dark_black_color {
  color: #1a1a1a;
}
.plankton_green {
  color: #004b43;
}
.white_color {
  color: #ffffff;
}
.black_color_popup {
  color: #4d4d4d;
}

.secondary_bg {
  background-color: #112f6a;
}
.light_blue_bg {
  background-color: #edf7f7;
}

.tertiary_bg {
  background-color: #fad100;
}
.white_shade_bg {
  background-color: #fbfbfb;
}
.white_bg {
  background-color: #ffffff;
}
/*****Fonts******/
.fn_Montserrat {
  font-family: "Montserrat", sans-serif;
}

.fn_Mulish {
  font-family: "Mulish", sans-serif;
}
.fn_Nunito {
  font-family: "Nunito", sans-serif;
}
.fn_Raleway {
  font-family: "Raleway", sans-serif;
}
.fn_Roboto {
  font-family: "Roboto", sans-serif;
}

.fw_300 {
  font-weight: 300;
}
.fw_400 {
  font-weight: 400;
}
.fw_500 {
  font-weight: 500;
}
.fw_600 {
  font-weight: 600;
}
.fw_700 {
  font-weight: 700;
}
.fw_800 {
  font-weight: 800;
}
.fw_900 {
  font-weight: 900;
}

.fs_10 {
  font-size: 10px;
}

.fs_11 {
  font-size: 11px;
}

.fs_12 {
  font-size: 12px;
}

.fs_13 {
  font-size: 13px;
}

.fs_14 {
  font-size: 14px;
}

.fs_15 {
  font-size: 15px;
}

.fs_16 {
  font-size: 16px;
}

.fs_17 {
  font-size: 17px;
}

.fs_18 {
  font-size: 18px;
}

.fs_18 {
  font-size: 19px;
}

.fs_20 {
  font-size: 20px;
}

.fs_21 {
  font-size: 21px;
}

.fs_22 {
  font-size: 22px;
}

.fs_23 {
  font-size: 23px;
}

.fs_24 {
  font-size: 24px;
}

.fs_25 {
  font-size: 25px;
}

.fs_26 {
  font-size: 26px;
}

.fs_27 {
  font-size: 27px;
}

.fs_28 {
  font-size: 28px;
}

.fs_29 {
  font-size: 29px;
}

.fs_30 {
  font-size: 30px;
}

.fs_31 {
  font-size: 31px;
}

.fs_32 {
  font-size: 32px;
}

.fs_33 {
  font-size: 33px;
}

.fs_34 {
  font-size: 34px;
}

.fs_35 {
  font-size: 35px;
}

.fs_38 {
  font-size: 38px;
}

.fs_40 {
  font-size: 40px;
}

.cursor_pointer {
  cursor: pointer;
}
.focus_outline__none:focus-visible {
  outline: none;
}
.pdt_1 {
  padding-top: 0.1rem;
}
.border_bottom {
  border-bottom: 2px solid #a5a5a5;
  width: fit-content;
}

.active_border_bottom {
  border-bottom: 3px solid #112f6a;
}
.navbar_hover_color:hover {
  color: #112f6a;
}
.border_right {
  border-right: 3px solid #a5a5a5;
  height: fit-content;
}
.overflow_x_scroll {
  overflow-x: scroll;
}
.scroll_none::-webkit-scrollbar {
  width: 0px !important;
  display: none;
}

.word_wrap {
  word-wrap: break-word;
}

@media only screen and (max-width: 576px) {
  .fs_sm_10 {
    font-size: 10px;
  }
  .fs_sm_13 {
    font-size: 13px;
  }
  .fs_sm_15 {
    font-size: 15px;
  }
  .fs_sm_20 {
    font-size: 20px;
  }
  .fs_sm_22 {
    font-size: 22px;
  }
}

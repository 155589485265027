.delete_modals_body {
  min-width: 315px;
}
.image_modals_body {
  min-width: 700px;
}
.delete_modals_body_content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
}
.delete_popup_button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 3px solid #112f6a;
  opacity: 1;
}
.rejection_message_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  opacity: 1;
  height: 7rem;
  resize: none;
}
.rejection_message_container:focus {
  outline: none !important;
}
.reset_pswrd_heading {
  line-height: 2rem;
}
.image_view_popup_size {
  width: 100%;
  height: 60vh;
}
@media only screen and (max-width: 768px) {
  .image_modals_body {
    min-width: 315px;
  }
}
.our_advisors_container {
    width: 92%;
  }
  .our_advisors-form-width {
    width: 35%;
    min-width: 300px;
  }
  
  .our_advisors_aprvls_vrsn_hstry_data_container {
    border: 1px solid var(---d9d9d9);
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #d9d9d9;
    border-radius: 11px;
    opacity: 1;
    width: 70%;
    overflow-y: auto;
    min-height: 30rem;
    height: fit-content;
  }
  


  
  @media only screen and (max-width: 576px) {
    .our_advisors_aprvls_vrsn_hstry_container {
      width: 100%;
    }
  }
  @media only screen and (max-width: 992px) {
    .our_advisors_aprvls_vrsn_hstry_data_container {
      width: 80%;
      height: 23rem;
      margin-left: 2rem;
    }
  }
  /* ///////////////// */
  .our-advisors-container{
    width: 92%;
  }
  .user_edit_profile_picture_container{
    width: 12rem;
  }
.our-advisors-form-width {
  width: 80%;
  }
  @media only screen and (max-width: 1450px) {
    .our-advisors-form-width {
      width: 100%;
    }
  }
.memberuser-table-container{position: relative;
overflow-x: auto;
min-width: 300px;
width: 100%;
min-height: 300px;
scrollbar-width: thin;}
  
  
  
  
  
  
  
  
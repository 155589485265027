.select-input-field ::placeholder {
  color: #464646;
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  font-weight: 400;
  opacity: 0.9;
  position: absolute;
  top: 0.6rem;
}


.selectField_error__border {
  border: 1.5px solid #cccccc;
  border-radius:10px;
}
.selectField_error__border:focus-visible {
outline: none;
border: none;
}
.selectField__border  {
  /* padding: 0.1rem 0.1rem; */
  border: 1.5px solid #cccccc;
  border-radius: 7px;
}
.selectField__border :focus-visible {
outline: none;
border: none;
}

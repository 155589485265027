.filtersidebar_main__container {
  box-shadow: 0px 0px 6px #00000029;
  width: 304px;
  position: fixed;
  top: 4.3rem;
  right: 0;
  height: calc(100vh - 4.3rem);
  z-index: 5;
}
.filtersidebar_btn__container {
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #f2f2f2;
}
.filter_input__datefrom :invalid + span:after {
  content: "Birthday";
  position: absolute;
  left: 0;
  top: 0;
}
.filter_input__datefrom :not(:focus):invalid {
  color: transparent;
}
.filter_input__datefrom :focus:invalid + span:after {
  display: none;
}

/* input::-webkit-datetime-edit{   display: none; }
input[type="date"] {
  text-align: right;
}

input[type="date"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
input[type="date"]:before {
  color: lightgrey;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}
input[type="date"]:focus:before {
  content: "" !important;
} */

@media only screen and (max-height: 800px) {
  .filtersidebar_form__container {
    height: fit-content;
  }
  .filtersidebar_main__container {
    overflow-y: scroll;
  }
}

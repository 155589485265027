.adminuser_table {
  border-spacing: 0;
  width: 100%;
}
.adminuser_table__header {
  display: table-header-group;
}
.adminuser_table__row {
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
}
.adminuser_table__body {
  display: table-row-group;
}
.adminuser_table__heading {
  font-family: "Montserrat", sans-serif;
  line-height: 1.5rem;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  background-color: #fbfbfb;
  color: #666666;
  font-size: 13px;
  font-weight: 500;
  padding: 11px 16px;
}

.adminuser_table__heading:first-of-type {
  border-top-left-radius: 14px;
}
.adminuser_table__heading:last-of-type {
  border-top-right-radius: 14px;
  width: 11rem;
  background-clip: padding-box;
}
.adminuser_approvals_table__heading:last-of-type {
  min-width: 6.5rem;
}

.adminuser_table__data {
  font-family: "Nunito", sans-serif;
  font-size: 0.85rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  display: table-cell;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 11px 16px;
  font-weight: 600;
  background-color: #ffff;
}
.adminuser_table__data:last-of-type {
  width: 11rem;
  background-clip: padding-box;
}

.adminuser_table_empty__data {
  width: 120px;
}
.adminrole_table_empty__data {
  width: 600px;
}

.adminuser_table__container {
  position: relative;
  overflow-x: auto;
  min-width: 300px;
  width: 100%;
  min-height: 560px;
  scrollbar-width: thin;
}
.adminuser_table__container::-webkit-scrollbar {
  background-color: #fbfbfb;
  width: 8px;
  height: 10px;
}
.adminuser_table__container::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
  box-shadow: inset 0 0 6px #00000029;
}
.adminuser_table__container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.adminuser_table_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 10rem;
}
.adminuser_table_mid_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 15rem;
}

.vr_line {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  height: 0.9rem;
  margin-top: 3px;
  background-color: currentcolor;
  opacity: 0.25;
}

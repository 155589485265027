/* div.wrapper {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

div.container {
  width: 100px;
}

button {
  border: 0;
  border-radius: 4px;
  background: hotpink;
  width: 100%;
  padding: 6px 8px;
  position: relative;
  text-align: left;
}

button:hover {
  cursor: pointer;
}

button::after {
  position: absolute;
  right: 6px;
  top: 4px;
  content: "\2191";
}

button.expanded::after {
  content: "\2193";
}

ul.options {
  border: 1px solid hotpink;
  display: none;
  list-style: none;
  padding: 4px 0;
  margin-top: -4px;
}

ul.show {
  display: block;
}

ul.options li {
  padding: 6px 10px;
}

ul.options li:active,
ul.options li:focus,
ul.options li:hover,
ul.options li[aria-selected="true"] {
  background: pink;
  cursor: pointer;
} */

.shareablemodal_input {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 2px solid #112f6a;
  border-radius: 5px;
  color: #337e76;
}

.shareablemodal_btn_copy {
  border: 2px solid #112f6a;
  border-radius: 5px;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 0.38rem 0.8rem 0.36rem 0.8rem;
  width: 100px;
  /* margin-right: 2.3rem; */
}

.shareablemodal_icon_copy {
  width: 15px;
  height: 16px;
}
.shareablemodal_container {
  max-width: 400px;
}


  .selectpaymeth_checkbox_label {
    position: relative;
    padding-left: 28px;
    margin-bottom: 1.2rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .selectpaymeth_checkbox_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .selectpaymeth_checkbox_label input:checked ~ .selectpaymeth_checkbox_span {
    background-color: #112F6A;
    border: none;
  }
  .selectpaymeth_checkbox_label
  input:checked
  ~ .selectpaymeth_checkbox_span:after {
  display: block;
}
.selectpaymeth_checkbox_span {
    position: absolute;
    top: 0;
    left: 0;
    height: 1rem;
    width: 1rem;
    border: 2px solid #112f6a;
    border-radius: 2px;
  }
  .selectpaymeth_checkbox_span:after {
    content: "";
    position: absolute;
    display: none;
  }

  .selectpaymeth_checkbox_span:after {
    left: 5px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

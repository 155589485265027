.admin_opportunities_view_table_container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #0000002e;
    border-radius: 10px;
    opacity: 1;
    height: 75vh;
  }
  .admin_view_back_button {
    border: none;
    background-color: transparent;
  }
  
  .admin_view_hr_line {
    width: 5px solid #707070;
    opacity: 0.26;
  }
  .admin_view_table_overFlow_container {
    overflow-y: auto;
    overflow-x: hidden;
    height: 85%;
  }
  .admin_view_table_container_top_position {
    width: 100%;
    z-index: 2;
    border-radius: 10px 10px 0px 0px;
  }
  @media only screen and (max-width: 576px) {
    .admin_view_table_container {
      background: transparent 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 0px transparent;
      border-radius: 0px;
      opacity: 1;
    }
  }
  
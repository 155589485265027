.oppor_contentedit__container {
  box-shadow: 0px 0px 10px #0000002e;
  border-radius: 16px;
  min-height: 400px;
}
.oppor_contentedit__header {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.oppor_content_edit__border {
  border: 1px solid #d9d9d9;
}

.oppor_content_edit__input:focus-visible {
  outline: none;
}
.oppor_content_edit_label__upload {
  padding: 1.1rem 0rem;
  border: 1px solid #d9d9d9;
  margin-top: 2px;
}
.oppor_content_edit__input {
  padding: 0.4rem 0.4rem;
  font-family: Nunito;
  border: 1px solid #d9d9d9;
}
.oppor_content_edit__input::placeholder {
  font-size: 13px;
  font-weight: 500;
  top: 1px;
  left: 0.2rem;
  position: relative;
}

.oppor_content_edit_btn__addsubsec {
  padding: 0.4rem 0rem;
}
.oppor_content_edit__vrline {
  border: 0.5px solid #afa9a946;
  height: 1.2rem;
  width: 0.5px;
}
.oppor_cont_edit_addsec__container {
  max-width: 50%;
  min-width: 500px;
}
.oppor_content_edit__quillcontainer {
  border: 1px solid #d9d9d9;
}
.quill {
  /* border-top: 1px solid #d9d9d9; */
  border-top-left-radius: 10px;
}
.quill .ql-snow.ql-toolbar {
  border: none;
  background: #ffffff;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  width: fit-content;
  margin-inline: auto;
  margin-top: 1rem;
}
.quill .ql-container.ql-snow {
  border: 0;
  min-height: 400px;
}

.oppor_content_edit_table__container {
  min-height: 300px;
}
.oppor_content_edit_subsec_btn__delete {
  top: 0.5rem;
}

.oppor_content_edit_table_radiobtn {
  height: 23px;
  width: 23px;
  accent-color: #112f6a;
}

@media only screen and (max-width: 576px) {
}
@media only screen and (max-width: 768px) {
  .oppor_cont_edit_addsec__container {
    max-width: 100%;
    min-width: auto;
  }
}

.oppoptunities_modals_body {
  width: 50% !important;
  min-width: 320px;
}
.oppoptunities_modals_body_content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
}
.faq_message_container {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  opacity: 1;
  height: 7rem;
  resize: none;
}
.faq_message_container:focus {
  outline: none !important;
}
.oppoptunities_minitable_ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 18rem;
}
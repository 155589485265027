.login_bg_image {
  background-image: url(../../assets/images/loginbackground.png);
  background-position: 0% 0%, right bottom;
  background-size: contain;
  background-repeat: no-repeat;
  /* height: 100%; */
}
.login_form_main__container {
  background-color: #192e66;
}

.login_input_error__border {
  padding: 0.35rem 0.8rem;
  border: 1.5px solid #cccccc;
}
.login_input__border {
  padding: 0.35rem 0.8rem;
  border: 1.5px solid #cccccc;
}
.login_input::placeholder {
  color: #a5a5a5;
  font-family: "Nunito", sans-serif;
  font-size: 12px;
  opacity: 0.9;
  position: absolute;
  top: 0.6rem;
}
.login_password_eye__icon {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 0.75rem;
  top: 0.6rem;
}
.login_btn {
  padding: 0.55rem 0rem;
}

@media only screen and (max-width: 992px) {
  .login_upicde__logo {
    width: 250px;
  }
}

.notify-me-container {
  width: 92%;
}
.notify-me-form-width {
  width: 37%;
  min-width: 300px;
}

.notify_me_aprvls_vrsn_hstry_data_container {
  border: 1px solid var(---d9d9d9);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  opacity: 1;
  width: 90%;
  min-height: 30rem;
  height: fit-content;
  overflow-y: auto;
}

@media only screen and (max-width: 576px) {
  .notify-me-container {
    width: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .notify_me_aprvls_vrsn_hstry_data_container {
    width: 90%;
    min-height: 23rem;
    height: fit-content;
    margin-left: 2rem;
  }
}
